import { Injectable } from '@angular/core';

@Injectable()
export class DataValidationService {

  constructor() { }

  public validateRow(name: string, lastName: string, email: string): void {

    let message;

    // tslint:disable-next-line: max-line-length
    const regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValidEmail = regEx.test(String(email).toLowerCase());

    if ((email && email !== '' && isValidEmail) && (name && name !== '')) {
      message = { status: 0, message: 'Success' };

      const username = name.replace(/\s/g, '');
      const userLastName = lastName.replace(/\s/g, '');

      if (username.length < 1) {
        message = {
          status: 1, message: 'Name should be at least 1 characters!',
        };
      } else if (userLastName.length < 2) {
        message = {
          status: 1, message: 'Last name should be at least 2 characters!',
        };
      }

    } else {
      if (!isValidEmail) {
        message = { status: 1, message: 'Email not valid!' };
      } else {
        message = { status: 1, message: 'Username and email must not be blanked!' };
      }
    }

    return message;
  }
}
