import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NbAuthService } from '@nebular/auth';
import { environment } from '../../../environments/environment';

@Injectable()
export class BrandService {

  private headers: any;

  private brandUrl = environment.host + 'brand';
  private usersBrandUrl = this.brandUrl + '/user-defined';

  constructor(private http: HttpClient,
    private authService: NbAuthService) {
  }

  private prepareHeaders () {
    this.headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' };
    this.authService.getToken().subscribe((authToken) => {
      this.headers['Authorization'] = this.headers['Authorization'] + authToken;
    });
  }

  getBrands () {
    this.prepareHeaders();
    return this.http.get(this.brandUrl, { headers: this.headers });
  }

  getUserBrands () {
    this.prepareHeaders();
    return this.http.get(this.usersBrandUrl, { headers: this.headers });
  }

  updateBrand(brandId: number, data: any) {
    this.prepareHeaders();
    return this.http.put(this.brandUrl + '/' + brandId, data, { headers: this.headers });
  }

  createBrand(data: any) {
    this.prepareHeaders();
    return this.http.post(this.brandUrl, data, { headers: this.headers });
  }
}
