
import { Injectable } from '@angular/core';

@Injectable()
export class Authentication {

  private user: any;

  getUser() {
    return this.user;
  }

  setUser(user: any) {
    this.user = user;
  }
}
