import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NbAuthService } from '@nebular/auth';
import { environment } from '../../../environments/environment';

@Injectable()
export class ApplianceService {

  private headers: any;
  private requestedModelUrl = environment.host + 'requested-models';
  private applianceTypeUrl = environment.host + 'appliance-type';
  private insertAppliance = environment.host + 'appliance-model-manual-add';
  private withoutManuals = environment.host + 'appliance-without-manual';
  private addMissingManual = environment.host + 'appliance-missing-manual-add';
  private applianceByModelNumber = environment.host + 'appliance-model';
  private applianceUserDefined = environment.host + 'appliance-model/user-defined';

  constructor(private http: HttpClient,
    private authService: NbAuthService) {
  }

  private prepareHeaders() {
    this.headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' };
    this.authService.getToken().subscribe((authToken) => {
      this.headers['Authorization'] = this.headers['Authorization'] + authToken;
    });
  }

  getRequestedModels() {
    this.prepareHeaders();
    return this.http.get(this.requestedModelUrl, { headers: this.headers });
  }

  getApplianceByModelNumber(modelNumber) {
    this.prepareHeaders();
    return this.http.get(this.applianceByModelNumber + '?modelNumber==' + modelNumber, { headers: this.headers });
  }

  getModelById(modelId) {
    this.prepareHeaders();
    return this.http.get(this.applianceByModelNumber + '?modelId==' + modelId, { headers: this.headers });
  }

  getRequestedModelById(id) {
    this.prepareHeaders();
    return this.http.get(this.requestedModelUrl + '/' + id, { headers: this.headers });
  }

  getApplianceTypes() {
    this.prepareHeaders();
    return this.http.get(this.applianceTypeUrl, { headers: this.headers });
  }

  getApplianceWithoutManuals() {
    this.prepareHeaders();
    return this.http.get(this.withoutManuals, { headers: this.headers });
  }

  updateExistingModel(data) {
    this.prepareHeaders();
    const options = {
      headers: this.headers,
    };
    return this.http.put(this.applianceByModelNumber + '/' + data.id, data, options);
  }

  insertNewModel(data) {
    this.prepareHeaders();
    const options = {
      headers: this.headers,
    };
    return this.http.post(this.insertAppliance, data, options);
  }

  addApplianceManual(data) {
    this.prepareHeaders();
    const options = {
      headers: this.headers,
    };
    return this.http.post(this.addMissingManual, data, options);
  }

  getUserDefinedModels() {
    this.prepareHeaders();
    return this.http.get(this.applianceUserDefined, { headers: this.headers });
  }

  showHideUserDefinedModelForAdmin(id: any, data: any) {
    const options = {
      headers: this.headers,
    };
    return this.http.put(this.applianceByModelNumber + '/' + id + '/show-hide-approve', data, options);
  }
}
