import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { environmentAll } from '../../../../environments/environment.all';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
      Copyright© <b><a href="https://homehub.center" target="_blank">HomeHub</a></b> 2019 - v{{version}}
    </span>
    <div class="socials">
      <a href="#" target="_blank" class="ion ion-social-github"></a>
      <a href="#" target="_blank" class="ion ion-social-facebook"></a>
      <a href="#" target="_blank" class="ion ion-social-twitter"></a>
      <a href="#" target="_blank" class="ion ion-social-linkedin"></a>
    </div>
  `,
})
export class FooterComponent {
  public version: string = environmentAll.app_version;
}
