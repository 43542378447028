import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { Authentication } from '../app/@core/data/authentication.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authentication: Authentication, private authService: NbAuthService, private router: Router) {
  }

  canActivate () {
    return this.authService.isAuthenticated()
      .pipe(
        tap(authenticated => {
          if (!authenticated) {
            this.router.navigate(['auth/login']);
          } else {
            this.authService.getToken().subscribe((token) => {
              if (token.getPayload().role !== 'admin') {
                this.authService.logout('email_password');
                this.router.navigate(['auth/login']);
              }
            });
          }
        }),
      );
  }
}