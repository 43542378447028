import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'ngx-manuals-upload',
    templateUrl: 'manuals-upload.component.html',
})

export class ManualsUploadComponent implements OnInit {
    appliance: any;
    error: any;
    objectKeys: any;

    constructor() {
        this.appliance = {};
    }

    ngOnInit() {
        this.objectKeys = Object.keys;
        this.appliance.manuals = {};
    }

    onUploadManualFinished(event) {
        this.error = {};
        const file = event.event;
        const manualName = file.fileName.substring(0, file.fileName.indexOf('.'));
        if (!this.appliance.manuals.hasOwnProperty(manualName)) {
            this.appliance.manuals[manualName] = {
                fileId: file.fileId,
                fileUrl: file.fileUrl,
            };
        }
    }

    removeManual(event, manualName) {
        delete this.appliance.manuals[manualName];
    }

    getManuals() {
        return this.appliance.manuals;
    }
}
