import { Injector, ErrorHandler, Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { Router } from '@angular/router';

export enum NbToastStatus {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  PRIMARY = 'primary',
  DANGER = 'danger',
  DEFAULT = 'default',
}

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private router;

  constructor(private toastrService: NbToastrService,
    private injector: Injector) { }
  handleError (error) {
    if (this.router == null) {
      this.router = this.injector.get(Router);
    }

    const message = error.message ? error.message : error.toString();

    this.toastrService.show('Danger',
      `ERROR ${message}`,
      { status: NbToastStatus.DANGER, destroyByClick: false });
    throw error;
  }
}
