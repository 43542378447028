import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NbAuthService } from '@nebular/auth';
import { environment } from '../../../environments/environment';

@Injectable()
export class NotificationService {

  private headers: any;

  private availableManualUrl = environment.host + 'available-manual-notification';

  constructor(private http: HttpClient,
    private authService: NbAuthService) {
  }

  private prepareHeaders () {
    this.headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' };
    this.authService.getToken().subscribe((authToken) => {
      this.headers['Authorization'] = this.headers['Authorization'] + authToken;
    });
  }

  notifyUser (id: any, data: any) {
    this.prepareHeaders();
    const options = {
      headers: this.headers,
    };
    return this.http.post(this.availableManualUrl + '/' + id, data, options);
  }
}
