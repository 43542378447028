import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NbAuthService } from '@nebular/auth';
import { environment } from '../../../environments/environment';

@Injectable()
export class AgentService {

  private headers: any;
  private baseUrl = environment.host + 'real-estate-agent';
  private realEstateWithAds = environment.host + 'real-estate-agent-banner-ads'

  constructor(private http: HttpClient,
    private authService: NbAuthService) {
  }

  private prepareHeaders() {
    this.headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' };
    this.authService.getToken().subscribe((authToken) => {
      this.headers['Authorization'] = this.headers['Authorization'] + authToken;
    });
  }

  getAgentById(id: number) {
    this.prepareHeaders();
    return this.http.get(this.baseUrl + '/' + id, { headers: this.headers });
  }

  getAgents() {
    this.prepareHeaders();
    return this.http.get(this.baseUrl, { headers: this.headers });
  }

  getAgentsWithAds() {
    this.prepareHeaders ();
    return this.http.get(this.realEstateWithAds, { headers: this.headers })
  }

  updateAgent(data:any) {
    this.prepareHeaders ();
    const options =  {
      headers: this.headers
    }
    return this.http.put(this.baseUrl + '/' + data.id, data, options);    
  }

  deleteAgent(data: any) {
    this.prepareHeaders();
    const options = {
      headers: this.headers,
      body: data,
    };
    return this.http.delete(this.baseUrl + '/' + data.id, options);
  }

  getZipCodesByAgentId(agentId) {
    this.prepareHeaders();
    const options = {
      headers: this.headers,
    };
    return this.http.get(this.baseUrl + '/' + agentId + '/zip-code', options);
  }

  createSubscription(agentId, data) {
    this.prepareHeaders();
    const options = {
      headers: this.headers,
    };
    return this.http.post(this.baseUrl + '/' + agentId + '/zip-code', data, options);
  }

  updateSubscription(agentId, data) {
    this.prepareHeaders();
    const options = {
      headers: this.headers,
    };
    return this.http.patch(this.baseUrl + '/' + agentId + '/zip-code/' + data.id, data, options);
  }

  deleteSubscription(agentId, zipCodeId) {
    this.prepareHeaders();
    const options = {
      headers: this.headers,
    };
    return this.http.delete(this.baseUrl + '/' + agentId + '/zip-code/' + zipCodeId, options);
  }

}


