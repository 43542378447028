import { Injectable } from '@angular/core';
import { NbAuthService } from '@nebular/auth';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class ImageService {
  private headers: any;
  private baseUrl = environment.host + 'storage';
  private unusablePhotoUrl = environment.host + 'requested-models-unusable';

  constructor(private http: HttpClient,
    private authService: NbAuthService) {
  }

  private prepareHeaders () {
    this.headers = { 'Authorization': 'Bearer ' };
    this.authService.getToken().subscribe((authToken) => {
      this.headers['Authorization'] = this.headers['Authorization'] + authToken;
    });
  }

  public uploadImage (formData: FormData) {
    this.prepareHeaders();
    const options = {
      headers: this.headers,
    };
    return this.http.post(this.baseUrl + '/banner', formData, options);
  }

  public uploadApplianceImage (formData: FormData, brandName: any) {
    this.prepareHeaders();
    const options = {
      headers: this.headers,
    };
    return this.http.post(this.baseUrl + '/model' + '/' + brandName, formData, options);
  }

  public uploadApplianceManual (formData: FormData) {
    this.prepareHeaders();
    const options = {
      headers: this.headers,
    };
    return this.http.post(this.baseUrl + '/manual', formData, options);
  }

  public unusablePhoto (id: string, data: any) {
    this.prepareHeaders();
    const options = {
      headers: this.headers,
    };
    return this.http.put(this.unusablePhotoUrl + '/' + id, data, options);
  }
}
