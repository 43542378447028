import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NbAuthService } from '@nebular/auth';
import { environment } from '../../../environments/environment';

@Injectable()
export class StatisticsService {

  private headers: any;
  private models = environment.host + 'statistics/models';
  private users = environment.host + 'statistics/users';
  private locations = environment.host + 'statistics/locations';
  private appliances = environment.host + 'statistics/appliances';

  constructor(private http: HttpClient,
    private authService: NbAuthService) {
  }

  private prepareHeaders() {
    this.headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' };
    this.authService.getToken().subscribe((authToken) => {
      this.headers['Authorization'] = this.headers['Authorization'] + authToken;
    });
  }

  getModels() {
    this.prepareHeaders();
    return this.http.get(this.models, { headers: this.headers });
  }

  getUsers() {
    this.prepareHeaders();
    return this.http.get(this.users, { headers: this.headers });
  }

  getLocations() {
    this.prepareHeaders();
    return this.http.get(this.locations, { headers: this.headers });
  }

  getAppliances() {
    this.prepareHeaders();
    return this.http.get(this.appliances, { headers: this.headers });
  }
}
