import { Component, Input, OnInit } from '@angular/core';

import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { Authentication } from '../../../@core/data/authentication.service';
import { AnalyticsService } from '../../../@core/utils/analytics.service';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {

  @Input() position = 'normal';

  user: any;
  isAuthenticated: boolean;

  userMenu = [
    // { title: 'Profile', link: '/pages/profile' },
    { title: 'Log out', link: '/auth/logout' }];

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private userService: Authentication,
    private analyticsService: AnalyticsService,
    private authService: NbAuthService) {
  }

  ngOnInit () {
    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {

        if (token.isValid()) {
          // here we receive a payload from the token and assigne it to our `user` variable
          this.user = token.getPayload();
          this.userService.setUser(this.user);
        }
      });

    this.authService.isAuthenticated()
      .subscribe((isAuth: boolean) => {
        this.isAuthenticated = isAuth;
      });
  }

  toggleSidebar (): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');

    return false;
  }

  toggleSettings (): boolean {
    this.sidebarService.toggle(false, 'settings-sidebar');

    return false;
  }

  goToHome () {
    this.menuService.navigateHome();
  }

  startSearch () {
    this.analyticsService.trackEvent('startSearch');
  }
}
