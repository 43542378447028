import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NbAuthService } from '@nebular/auth';
import { environment } from '../../../environments/environment';

@Injectable()
export class UserService {

  private headers: any;
  private baseUrl = environment.host + 'user';

  constructor(private http: HttpClient,
    private authService: NbAuthService) {
  }

  private prepareHeaders() {
    this.headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' };
    this.authService.getToken().subscribe((authToken) => {
      this.headers['Authorization'] = this.headers['Authorization'] + authToken;
    });
  }

  getUser(id: any) {
    this.prepareHeaders();
    const options = {
      headers: this.headers,
    };
    return this.http.get(this.baseUrl + '/' + id, options);
  }

  getUsers() {
    this.prepareHeaders();
    return this.http.get(this.baseUrl, { headers: this.headers });
  }

  updateUser(data: any) {
    this.prepareHeaders();
    const options = {
      headers: this.headers,
    };
    return this.http.put(this.baseUrl + '/' + data.id, data, options);
  }

  deleteUser(data: any) {
    this.prepareHeaders();
    const options = {
      headers: this.headers,
      body: data,
    };
    return this.http.delete(this.baseUrl + '/' + data.id, options);
  }

  createUser(data: any) {
    this.prepareHeaders();
    const options = {
      headers: this.headers,
    };
    return this.http.post(this.baseUrl, data, options);
  }
}

