import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NbAuthService } from '@nebular/auth';
import { environment } from '../../../environments/environment';

@Injectable()
export class RealEstateService {

  private headers: any;
  private baseRealEstate = environment.host + 'real-estate';
  private baseRealEstateEstateAgent = environment.host + 'real-estate-estate-agent';

  constructor(private http: HttpClient,
    private authService: NbAuthService) {
  }

  private prepareHeaders() {
    this.headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' };
    this.authService.getToken().subscribe((authToken) => {
      this.headers['Authorization'] = this.headers['Authorization'] + authToken;
    });
  }

  getAllRealEstates() {
    this.prepareHeaders();
    return this.http.get(this.baseRealEstateEstateAgent, { headers: this.headers });
  }

  getRealEstatesByZipCodes(zipCodes: string[]) {
    this.prepareHeaders();
    return this.http.get(this.baseRealEstate, { headers: this.headers, params: { zipCodes } });
  }

  updateRealEstateBanner(realEstateId: any, bannerId: any) {
    this.prepareHeaders();
    const options = {
      headers: this.headers
    }
    return this.http.put(this.baseRealEstateEstateAgent + '/' + realEstateId, bannerId, options);
  }

  getAllBannerAds() {
    this.prepareHeaders();
    const options = {
      headers: this.headers
    }
    return this.http.get(environment.host + 'ads-all', options);
  }
}
