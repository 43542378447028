import { Component, EventEmitter, Output, ViewChild, Input } from '@angular/core';
import { ImageService } from '../../../@core/data/image.service';

class ImageSnippet {
  pending: boolean = false;
  status: string = 'init';

  constructor(public src: string, public file: File) { }
}

@Component({
  selector: 'ngx-file-upload',
  templateUrl: 'file-upload.component.html',
  styleUrls: ['file-upload.component.scss'],
})
export class FileUploadComponent {
  @ViewChild('uploadForm') testFormEl;

  @Input()
  brandName

  @Input()
  type

  @Output() onUpload: EventEmitter<any> = new EventEmitter();
  selectedFile: ImageSnippet;

  isLoading: boolean;

  constructor(private imageService: ImageService) { }

  private onSuccess(res) {
    this.loaded();
    this.onUpload.emit({ event: res });
  }

  private onError(err) {
    this.loaded();
  }

  loading() {
    this.isLoading = true;
  }

  loaded() {
    this.isLoading = false;
  }

  uploadBannerImage(formData) {
    this.loading();
    this.imageService.uploadImage(formData).subscribe(
      (res) => {
        this.onSuccess(res);
      },
      (err) => {
        this.onError(err);
      });
  }

  uploadApplianceImage(formData, brandName) {
    this.loading();
    this.imageService.uploadApplianceImage(formData, brandName).subscribe(
      (res) => {
        this.onSuccess(res);
      },
      (err) => {
        this.onError(err);
      });
  }

  uploadApplianceManual(formData) {
    this.loading();
    this.imageService.uploadApplianceManual(formData).subscribe(
      (res) => {
        this.onSuccess(res);
      },
      (err) => {
        this.onError(err);
      });
  }



  public message = '';

  files(files) {
    this.message = '';

    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append(`file${i}`, files.item(i), files.item(i).name);
    }
    if (this.type == 'banner') {
      this.uploadBannerImage(formData);
    } else if (this.type == 'model') {
      this.uploadApplianceImage(formData, this.brandName);
    } else if (this.type == 'manual') {
      this.uploadApplianceManual(formData);
    }
  }

}
