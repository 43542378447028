import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-dialog-brand',
  templateUrl: './dialog-brand-component.html',
})
export class DialogBrandComponent {
    constructor(protected dialogRef: NbDialogRef<DialogBrandComponent>) {
    }

    cancel() {
      this.dialogRef.close();
    }

    submit(name: string) {
      this.dialogRef.close(name);
    }
}
