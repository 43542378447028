import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Authentication } from './authentication.service';
import { UserService } from './user.service';
import { AgentService } from './agent.service';
import { ApplianceService } from './appliance.service';
import { BrandService } from './brand.service';
import { RealEstateService } from './real-estate.service';
import { StateService } from './state.service';
import { ImageService } from './image.service';
import { DataValidationService } from './data-validation.service';
import { NotificationService } from './notification.service';
import { StatisticsService } from './statistics.service';

const SERVICES = [
  Authentication,
  UserService,
  AgentService,
  ApplianceService,
  StatisticsService,
  BrandService,
  RealEstateService,
  StateService,
  ImageService,
  DataValidationService,
  NotificationService,
];

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    ...SERVICES,
  ],
})
export class DataModule {
  static forRoot (): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: DataModule,
      providers: [
        ...SERVICES,
      ],
    };
  }
}
