import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { DefaultEditor } from 'ng2-smart-table';

@Component({
  selector: 'ngx-datepicker',
  templateUrl: 'datepicker.component.html',
  styleUrls: ['datepicker.component.scss'],
})

/*
* This component is only for use of ng2 smart table
*/

export class DatePickerComponent extends DefaultEditor implements AfterViewInit {
  @ViewChild('date') date: ElementRef;
  @ViewChild('datepicker') datepicker: ElementRef;

  constructor() {
    super();
  }

  ngAfterViewInit(): void {

  }

  updateValue() {

  }

}
